<template>
    <div class="grid crud-demo">
        <Loader v-model="loading" />
        <div class="col-12">
            <div class="card">
                <Toast />
                <h2>Ajuste Físico de Inventario</h2>
                <BasicFormToolbar @save="save" @refresh="refresh" :actions="['save', 'refresh']" />

                <BasicDatatable :rowaction="true" :headers="headers" :rows="entity.items" @cell-editing-complete="editarExistencias" />
            </div>
        </div>
    </div>
</template>

<script>
import Session from '../../../mixins/sessionMixin';
import Loader from '../../../components/general/Loader.vue';
import BasicFormToolbar from '../../../components/general/BasicFormToolbar.vue';
import BasicDatatable from '../../../components/general/BasicDatatable.vue';
import { ErrorToast, HeaderGrid } from '../../../utilities/General';
import { FilterMatchMode } from 'primevue/api';

//import { InventoryArticle } from '../../../models/inventarios/InventoryArticle';
//import { InventoryMovementWarehouse } from '../../../models/inventarios/InventoryMovementWarehouse';
import { InventoryAdjust } from '../../../models/inventarios/InventoryAdjust';
import { InventoryConfiguration } from '../../../models/inventarios/InventoryConfiguration';
//import { InventoryMovementWarehouseItemsUEPS } from '../../../models/inventarios/InventoryMovementWarehouseItemsUEPS';
import { InventoryMovementWarehouseUEPS } from '../../../models/inventarios/InventoryMovementWarehouseUEPS';

export default {
    mixins: [Session],
    data() {
        return {
            entity: null,
            uploadURL: null,
            newDialog: false,
            deleteDialog: false,
            loading: false,
            filters: {},
            headers: [
                new HeaderGrid('Almacen', 'strwarehouse'),
                new HeaderGrid('SubAlmacen', 'strsubwarehouse'),
                new HeaderGrid('SKU', 'id_article'),
                new HeaderGrid('Nombre', 'strarticle'),
                new HeaderGrid('Existencia Sistema', 'quantity'),
                new HeaderGrid('Existencias Reales', 'adjust', { editable: { component: 'InputText' } }),
            ],
        };
    },
    components: {
        Loader,
        BasicDatatable,
        BasicFormToolbar,
    },
    created() {
        console.log('Sesion: ', this.session);
        this.entity = new InventoryAdjust(this.session);
        this.uploadURL = this.$config.api_route + 'Inventory/InventoryAdjust/Import/' + this.session.empresa + '/' + this.session.sucursal + '/' + this.session.usuario;
        this.initFilters();
    },
    async mounted() {
        await this.refresh();
    },
    methods: {
        editarExistencias(value) {
            console.log('Editar Existencias: ', value);
        },
        initFilters() {
            this.filters = { global: { value: null, matchMode: FilterMatchMode.CONTAINS } };
        },
        async refresh() {
            this.loading = true;
            try {
                this.configuration = await new InventoryConfiguration(this.session).getCustom();
                var conf = this.configuration[0].value;

                if (conf == 1) {
                    this.entity.items = await new InventoryAdjust(this.session).getInventary();
                } else {
                    this.entity.items = await new InventoryMovementWarehouseUEPS(this.session).getInventary();
                }
                console.log('entity.items: ', this.entity);
            } catch (ex) {
                this.$toast.add(new ErrorToast(ex));
            } finally {
                this.loading = false;
            }
        },
        async save() {
            console.log('Guardar toda tabla;');
            console.log(this.entity);
            this.loading = true;
            try {
                //Validar que no exista campos vacíos
                //  let valid = true;
                for (let i = this.entity.items.length - 1; i >= 0; i--) {
                    const element = this.entity.items[i];
                    if (element.adjust == '' || element.adjust == null) {
                        this.entity.items.splice(i, 1);
                    }
                }
                //  if (!valid) throw 'Favor de validar los campos.';
                console.log(this.entity.items);
                //Guarda
                let save = await this.entity.postInventoryAdjust();
                let save2 = await this.entity.postInventoryAdjustUEPS();
                console.log(save);
                if (save && save2) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Guardar Ajuste',
                        detail: 'Información actualizada con exito',
                        life: '3000',
                    });
                    this.refresh();
                } else {
                    throw 'Existe error al procesar información.';
                }
            } catch (ex) {
                this.$toast.add(new ErrorToast(ex));
            } finally {
                this.loading = false;
            }
        },
        async edit(entity) {
            console.log('Editar por registro;');
            this.loading = true;
            try {
                //Validar que el campo no esté vacío y remover aquellos que no se editarán.
                let valid = true;
                let items = this.entity.items;
                console.log(items);
                for (let i = 0; i < items.length; i++) {
                    if (items[i].id_article == entity.id_article) {
                        if (!items[i].adjust) {
                            items.splice(i, 1);
                        }
                    }
                }
                //   if (!valid) throw 'Favor de validar los campos.';
                this.entity.items = items;
                console.log(this.entity);
                console.log(valid);

                //Guarda
                let save = await this.entity.postInventoryAdjust();
                await this.entity.postInventoryAdjustUEPS();
                console.log('Save: ', save);
                if (save) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Guardar Ajuste',
                        detail: 'Información actualizada con exito',
                        life: '3000',
                    });
                    this.refresh();
                } else {
                    throw 'Existe error al procesar información.';
                }
            } catch (ex) {
                this.$toast.add(new ErrorToast(ex));
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style>
/*************/
</style>