import { Abstract } from "./Abstract";
import axios from "axios";
export class InventoryAdjust extends Abstract {
    constructor(session) {
        super('Inventory/InventoryMovementWarehouse', session);
        this.id_movementtype = null;
        this.id_movement = null;
        this.date_mov = null;
        this.adjustComment = null;
        this.items = [];
        this.adjust = null;
    }

    async getInventary() {
        let response = await axios.get(this.controller + "/getInventary", {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }

    async postInventoryAdjust(){
        let response = await axios.post(this.controller + "/postInventoryAdjust", this, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
            }
        });
        return response.data;
    }

    async postInventoryAdjustUEPS(){
        let response = await axios.post("Inventory/InventoryMovementWarehouseUEPS/postInventoryAdjustUEPS", this, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
            }
        });
        return response.data;
    }
}